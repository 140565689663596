import { createAtom } from '/js/vendor/xoid.custom.js'
import { catalogApi, cartApi, userApi } from '/js/api.js'
import { flatToHierarchy } from '/js/utils.js'
import notifs from '/js/notifications.js';

import i18n from "/js/i18n.js"

import { Modal } from '/js/vendor/bootstrap.custom.js';


/* работа с localStorage */
const getLocalStorage = (key) =>
  JSON.parse(localStorage.getItem(key) || null)

const setLocalStorage = (key) => (state) =>
  localStorage.setItem(key, JSON.stringify(state))


/* Глобальные данные с сервера */
const globalData = JSON.parse(document.getElementById("global-data").text);
export const globalStore = createAtom(globalData)


/* Глобальные данные с сохранением в localStorage  */
export const persistStore = createAtom((read) => {
	return getLocalStorage('sch-global') || {
		token: '',
		city: read(globalStore).city,
		currency: read(globalStore).currency,
	}
})
persistStore.subscribe(setLocalStorage('sch-global'))


/* статус пользователя  */
export const loggedIn = createAtom(read => {
	return read(persistStore).token !== ''
})


/* Контроллер поиска */
export const globalSearch = createAtom('', atom => ({
	init: () => {
		const params = new URLSearchParams(window.location.search)
		if (params.has('q')) atom.set(params.get('q'));
	}
}))


/* категории товаров  */
export const categories = createAtom([], (atom) => ({
  getList: () => {
		catalogApi.getCategories().then(resp => {
			if (typeof resp === 'object') {
				let categs = []

				for (let [catname, catdata] of Object.entries(resp)) {
					let newObj = {
						id: catname
					}
					for (let [prop, data] of Object.entries(catdata)) {
						if (!prop.startsWith('@') && prop !== 'children') {
							if (prop === 'parents') newObj['parent'] = data[0]
							else newObj[prop] = data;
						}
					}
					categs.push(newObj);
				}
				atom.set( categs )
			}

		})
	},
}))


export const categsTree = createAtom(read => {
	return flatToHierarchy(read(categories))
})


/* Корзина  */
export const cartStore = createAtom([], (atom) => ({

  getItems: () => {
		return cartApi.get_cart().then(response => {
			if (response?.result === 'Success') atom.set( response.cart );
		})
	},

	addItem: (id, offer_id) => {
		let isInCart = atom.value.findIndex(o => o.proposal_id === id) >= 0

		if (id && !isInCart) {
			const handleResp = resp => {
				if (resp) {
					if (resp.result === 'Success') {
						atom.set(resp.cart)
						notifs.enqueue({ severity: 'success', title: i18n.t('cart.added') })
					}
					else if (resp.result === 'Error') {
						notifs.enqueue({ severity: 'danger', title: resp.message })
					}
				}
				return resp
			}
			if (offer_id) {
				return cartApi.add_request_to_cart(id, offer_id).then(handleResp);
			}
			else {
				return cartApi.add_to_cart(id).then(handleResp);
			}
		}
	},

	deleteItem: (id) => {	// id может быть массивом идентификаторов
		return cartApi.delete_from_cart(id).then(response => {
			if (response?.result === "Success") {
				atom.set( response.cart )
				notifs.enqueue({
					severity: 'success',
					title: i18n.t('suceeded.saved')
				})
			}
		});
	},

	changeItemQty: (id, qty) => {
		if (qty === 0) {
			cartApi.delete_from_cart(id).then(response => {
				if (response?.result === "Success") {
					atom.set( response.cart )
					notifs.enqueue({
						severity: 'success',
						title: i18n.t('suceeded.saved')
					})
				}
			})
		}
		else {
			cartApi.change_qty(id, qty).then(response => {
				if (response) {
					if (response.cart) atom.set( response.cart );

					if (response.result === "Success") {
						notifs.enqueue({
							severity: 'success',
							title: i18n.t('suceeded.saved')
						})
					}
					if (response.result === "Error") {
						notifs.enqueue({
							severity: 'error',
							title: response.message
						})
					}
				}
			});
		}
	},
}))


/* Данные пользователя  */
export const userDataStore = createAtom((read) => {
	return getLocalStorage('sch-user') || {
		"id": "",
		"img": "",
		"type": "",
		"firstname": "",
		"lastname": "",
		"patronimic": "",
		"birthdate": "",
		"sex": "",
		"email": "",
		"phone": "",
		"rating": 0,
		"reviews": 0,
		"locations": [],
		"defaultLocation": ''
	}
},
(atom) => ({
  get_data: () => {
		userApi.getUserData().then(resp => {
			if (resp && resp.status === 'Success') {
				atom.set(resp.result)
			}
		})
	},
  save_data: (data) => {
		return userApi.saveUserData(data).then(resp => {
			if (resp?.status === 'Success') {
				atom.set(resp.result)
				notifs.enqueue({
					severity: 'success',
					title: i18n.t('suceeded.saved')
				})
			}
			return resp
		})
	},
	setDefaultAddr: (id) => {
		return userApi.setDefaultAddr(id).then(resp => {
			if (resp) {
				if (resp.status === 'Success') {
					atom.focus('defaultLocation').set(resp.result)
				}
			}
			return resp
		})
	},
	saveAddress: (data) => {
		return userApi.saveAddress(data).then(resp => {
			if (resp) {
				if (resp.status === 'Success') {
					userDataStore.focus("locations").set(resp.result.locations)
					userDataStore.focus("defaultLocation").set(resp.result.defaultLocation)
					notifs.enqueue({
						severity: 'success',
						title: i18n.t('suceeded.saved')
					})
				}
			}
			return resp
		})
	},
	removeAddress: (id) => {

		return userApi.removeAddress(id).then(resp => {
			if (resp) {
				if (resp.status === 'Success') {
					userDataStore.focus("locations").set(resp.result.locations)
					userDataStore.focus("defaultLocation").set(resp.result.defaultLocation)
					notifs.enqueue({
						severity: 'success',
						title: i18n.t('suceeded.saved')
					})
				}
			}
			return resp
		})
	},
}))
userDataStore.subscribe(setLocalStorage('sch-user'))


/* Передача данных в модальные окна и обратно */
export const modalsListStore = createAtom({}, (atom) => ({
  add: (event) => {
		atom.focus(event.target.id).set({
			invoker: event.relatedTarget,
			props: null,
			result: null,
		})
	},
  remove: (event) => {
		let id = event?.target?.id
		if (id) delete atom.value[id];
	},
  set_props: (id, data) => {
		if (id) atom.value[id].props = data;
	},
  set_result: (id, data) => {
		if (id) atom.value[id].result = data;
	},
}))

document.addEventListener('show.bs.modal', (e) => {
	modalsListStore.actions.add(e)

	let props = e.relatedTarget?.dataset.inputProps
	let id = e.target.id
	if (props) {
		modalsListStore.actions.set_props(id, JSON.parse(props))
	}
})

document.addEventListener('hidden.bs.modal', (e) => {
	modalsListStore.actions.remove(e)
})


/* Загрузка нужных данных */
export async function init() {
	let inAccount = window.location.pathname.startsWith('/account')
	if (inAccount && !loggedIn.value)
		Modal.getOrCreateInstance(document.getElementById('authModal')).show();

	globalSearch.actions.init()

	await categories.actions.getList()
	await cartStore.actions.getItems()

	if (loggedIn.value) {
		await userDataStore.actions.get_data()
	}
}

init()
